import i18n from "../utils/i18n";

export const tuitionFee = 1200; // Вартість навчання

// Конфігурація курсів
export const coursesConfig = [
  {
    category: "language_and_integration_courses",
    courses: [
      // { id: "1", name: "lang_dutch_course" },
      { id: "2", name: "integration_course" },
    ],
  },
  {
    category: "professional_courses",
    courses: [
      { id: "3", name: "pro_restaurant" },
      { id: "4", name: "pro_logistics" },
      { id: "5", name: "pro_retail" },
      { id: "6", name: "pro_hotel" },
      { id: "7", name: "pro_entrepreneurship" },
      { id: "8", name: "pro_food_industry" },
      { id: "9", name: "pro_economy" },
    ],
  },
];

// Відкриття посилання на відео
export const openVideoLink = () => {
  const tutorials = {
    ua: "https://www.youtube.com/watch?v=pyEou2lmFqc",
    ru: "https://www.youtube.com/watch?v=6fsS2oUTyPQ",
    en: "https://www.youtube.com/watch?v=Ly0ZmFG_9lg",
  };

  const currentLanguage = i18n.language; // Отримуємо поточну мову
  const videoLink = tutorials[currentLanguage] || tutorials.en;
  window.open(videoLink, "_blank");
};
