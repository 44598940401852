import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import FileSaver from "file-saver";
import JSZipUtils from "jszip-utils";
import { toast } from "react-toastify";
import { generateInvoiceNumber } from "../textHandlers/generateInvoiceNumber";
import { formatDate } from "../textHandlers/date";

import infoFile from "../../database/info.docx";
import invoiceFile from "../../database/invoice.docx";

// Функція для вибору файлу
const getFile = (documentType) => {
  if (documentType === "invoice") return invoiceFile;
  if (documentType === "info") return infoFile;
  console.log(
    `Невірний формат: ${documentType}. Доступні формати: "invoice", "info".`
  );
  return null;
};

export async function generateDocument(
  userData,
  documentType,
  firestore,
  callback,
  extraData = {}
) {
  let file = getFile(documentType);
  if (!file) return;

  JSZipUtils.getBinaryContent(file, async (error, content) => {
    if (error) {
      console.error("Помилка при завантаженні файлу: ", error);
      toast.error("Помилка при завантаженні файлу");
      return;
    }

    try {
      const invoiceNumber =
        documentType === "invoice"
          ? await generateInvoiceNumber(firestore)
          : null;

      const zip = new PizZip(content);
      const doc = new Docxtemplater(zip, {
        paragraphLoop: true,
        linebreaks: true,
      });

      const amountDue = extraData.amountDue ?? "1200.00";

      const data = {
        displayName: userData.displayName,
        selectedCourse: userData.selectedCourse,
        phoneNumber: userData.phoneNumber,
        email: userData.email,
        fullAddress: userData.fullAddress,
        birthDate: userData.birthDate,

        relativeName: userData.relativeDisplayName || "Не вказано",
        relativePhone: userData.relativePhoneNumber || "Не вказано",
        relativeBirthDate: userData.relativeBirthDate || "Не вказано",
        relativeEmail: userData.relativeEmail || "Не вказано",
        relativeFullAddress: userData.relativeFullAddress || "Не вказано",

        payerName:
          userData.relativeDisplayName &&
          userData.relativeDisplayName !== "Не вказано"
            ? userData.relativeDisplayName
            : userData.displayName,
        payerAddress:
          userData.relativeFullAddress &&
          userData.relativeFullAddress !== "Не вказано"
            ? userData.relativeFullAddress
            : userData.fullAddress,
        payerEmail:
          userData.relativeEmail && userData.relativeEmail !== "Не вказано"
            ? userData.relativeEmail
            : userData.email,

        amountDue,
        invoiceNumber: invoiceNumber,
        invoiceDate: formatDate(new Date()),
        regDate: userData.regDate || "2024 рік",
        premiumStatus: userData.premium ? "Так" : "Ні",
      };

      await doc.renderAsync(data);

      const out = doc.getZip().generate({
        type: "blob",
        mimeType:
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      if (documentType === "invoice" && callback) {
        callback(out);
      } else {
        FileSaver.saveAs(out, `${documentType}.docx`);
        toast.success("Файл успішно згенеровано");
      }
    } catch (error) {
      const e = {
        message: error.message,
        name: error.name,
        stack: error.stack,
        properties: error.properties,
      };
      console.log("Rendering error:", JSON.stringify({ error: e }));
      toast.error(`Помилка: ${error.message}`);
    }
  });
}
