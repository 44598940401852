import ReactDOMServer from "react-dom/server";
import React from "react";
import { collection, addDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";

// utils
import { generateDocument } from "../fileHandlers/docxTemplater";

// Components
import { WelcomeUA, WelcomeUAText } from "../../database/emails/WelcomeUA";
import {
  WelcomeUA25,
  WelcomeUA25Text,
} from "../../database/emails/WelcomeUA25";
import {
  WelcomeUA100,
  WelcomeUA100Text,
} from "../../database/emails/WelcomeUA100";

export const SendEmail = async (firestore, storage, userData, emailType) => {
  const mailCollection = collection(firestore, "mail");
  const emailAddresses =
    userData.relativeEmail && userData.relativeEmail !== "Не вказано"
      ? [userData.email, userData.relativeEmail]
      : [userData.email];

  // Формуємо пропси для email
  const emailProps = {
    name: userData.displayName || "Студент",
    fullPrice: 1200,
    discountPrice: 1200 * 0.75,
    registrationFee: 87,
  };

  // Визначаємо кінцеву ціну курсу
  let amountDue = emailProps.fullPrice; // За замовчуванням - повна ціна
  if (emailType === "discount100") {
    amountDue = emailProps.registrationFee;
  }

  // Визначаємо, який лист відправляти
  let emailHtml, emailText;

  if (emailType === "discount25") {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA25 {...emailProps} />
    );
    emailText = WelcomeUA25Text(emailProps);
  } else if (emailType === "discount100") {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA100 {...emailProps} />
    );
    emailText = WelcomeUA100Text(emailProps);
  } else {
    emailHtml = ReactDOMServer.renderToStaticMarkup(
      <WelcomeUA {...emailProps} />
    );
    emailText = WelcomeUAText(emailProps);
  }

  // ГЕНЕРУЄМО ЛИСТА
  generateDocument(
    userData,
    "invoice",
    firestore,
    async (blob) => {
      const newDate = new Date();
      const formattedDate = `${String(newDate.getDate()).padStart(
        2,
        "0"
      )}.${String(newDate.getMonth() + 1).padStart(
        2,
        "0"
      )}.${newDate.getFullYear()}`;

      const storageRef = ref(
        storage,
        `invoices/${userData.displayName}-${formattedDate}.docx`
      );

      try {
        await uploadBytes(storageRef, blob);
        const downloadURL = await getDownloadURL(storageRef);

        const emailData = {
          to: emailAddresses,
          message: {
            subject: "INTERNATIONAL ACADEMY MBO",
            text: emailText,
            html: emailHtml,
            attachments: [
              {
                filename: "invoice.docx",
                path: downloadURL,
              },
            ],
          },
        };

        await addDoc(mailCollection, emailData);
        console.log("Registration email has been added to the collection.");
        toast.success("Check your email");
      } catch (error) {
        console.error("Error adding registration email: ", error);
        toast.error("Error adding registration email.");
      }
    },
    { amountDue } // Додаємо фінальну ціну як додатковий параметр
  );
};

// Активація промокоду після сабміту
export const markPromoAsUsed = async (firestore, promoCode) => {
  if (!promoCode) return;

  if (!/^\d{5}-\d$/.test(promoCode)) {
    console.warn(`⚠️ Невірний формат промокоду: ${promoCode}`);
    return;
  }

  const [promoId, promoValue] = promoCode.split("-");
  const promoRef = doc(firestore, "promo", promoId);

  try {
    const promoSnap = await getDoc(promoRef);
    if (!promoSnap.exists()) throw new Error("Promo code not found");

    const promoData = promoSnap.data();
    let availableCodes = promoData.available
      ? promoData.available.split(", ")
      : [];
    let activeCodes = promoData.active ? promoData.active.split(", ") : [];

    // Перевіряємо, чи код дійсно доступний
    if (!availableCodes.includes(promoValue)) {
      throw new Error("Promo code not available");
    }

    // Переносимо код з available у active
    availableCodes = availableCodes.filter((code) => code !== promoValue);
    activeCodes.push(promoValue);

    // Оновлюємо документ промокоду
    await updateDoc(promoRef, {
      available: availableCodes.join(", "),
      active: activeCodes.join(", "),
    });

    console.log(`✅ Промокод ${promoCode} успішно активовано`);
  } catch (error) {
    console.error(`🚨 Помилка активації промокоду: ${error.message}`);
    throw error;
  }
};
