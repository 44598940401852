import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import Select from "react-select";
import { toast } from "react-toastify";

// Firebase
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

// Utils
import {
  updateCurrentUserProfile,
  createUserProgress,
} from "../../../utils/network/userActions";
import { closeHandler } from "../../../utils/dom/open-close-modal";
import { DownloadFile } from "../../../utils/fileHandlers/downloadFile";
import {
  SendEmail,
  markPromoAsUsed,
} from "../../../utils/network/networkActions";
import { formatDate } from "../../../utils/textHandlers/date";
import { formatDateTime } from "../../../utils/textHandlers/date";

// Context
import { Context } from "../../../index";

// Lib
import defaultPhoto from "../../../lib/noname-ava2.png";

export const RegisterTabComponent = ({ setShowLoginModal }) => {
  const { t } = useTranslation();
  const { auth, firestore, storage, userData, setUserData } =
    useContext(Context);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [address, setAddress] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [promo, setPromo] = useState({});
  const [inputState, setInputState] = useState("idle");
  const [permission, setPermission] = useState(false);
  const [isOffer, setIsOffer] = useState(false);

  const [below18, setBelow18] = useState(false);
  const [relativeFirstName, setRelativeFirstName] = useState("");
  const [relativeLastName, setRelativeLastName] = useState("");
  const [relativeBirthDate, setRelativeBirthDate] = useState("");
  const [relativeAddress, relativeSetAddress] = useState("");
  const [relativeEmail, setRelativeEmail] = useState("");
  const [relativePhoneNumber, setRelativePhoneNumber] = useState("");
  const [relativeSelectedCountry, setRelativeSelectedCountry] = useState(null);

  const handleCloseModal = (e) => {
    if (e && e.target && e.target.classList.contains("modal-overlay")) {
      setShowLoginModal(false);
    }
    setShowLoginModal(false);
    closeHandler();
  };

  useEffect(() => {
    const options = countryList().getData();
    setCountries(options);
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();

    // перевіряєм форму на помилки
    const formIsValid = await validateForm();
    if (!formIsValid) return;

    // Визначаємо тип листа
    const emailType = promo?.promoCode ? "discount100" : "";

    try {
      // логінимся на сайт
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // оновлюємо профіль на сайті
      const user = userCredential.user;
      await updateCurrentUserProfile(
        auth,
        user,
        firstName,
        lastName,
        defaultPhoto
      );

      // костиль для relativeFullAddress
      // щоб не давало undefined
      let relativeFullAddress = "";
      if (relativeSelectedCountry?.label && relativeAddress) {
        relativeFullAddress = `${relativeSelectedCountry.label}, ${relativeAddress}`;
      }

      // костиль для relativeDisplayName
      // щоб не давало пробела
      let relativeDisplayName = "";
      if (relativeFirstName && relativeLastName) {
        relativeDisplayName = `${relativeFirstName} ${relativeLastName}`;
      }

      // формуємо пакет для відправки
      const userData = {
        displayName: firstName + " " + lastName,
        uid: user.uid,
        premium: false,
        installments: "no installments",
        email,
        photoURL: "",
        birthDate,
        phoneNumber,
        promo: Object.keys(promo).length ? promo : { promoCode: "Не вказано" },
        fullAddress: selectedCountry.label + ", " + address,
        regDate: formatDate(new Date()),

        relativeDisplayName: relativeDisplayName || "Не вказано",
        relativeBirthDate: relativeBirthDate || "Не вказано",
        relativeEmail: relativeEmail || "Не вказано",
        relativePhoneNumber: relativePhoneNumber || "Не вказано",
        relativeFullAddress: relativeFullAddress || "Не вказано",
      };

      // зберігаємо дані користувача в Firestore з використанням UID як ідентифікатора
      await setDoc(doc(firestore, "users", user.uid), userData);

      // створюємо початковий прогрес студента
      await createUserProgress(userData, firestore);

      // відправляємо електронного листа новому користувачу
      await SendEmail(firestore, storage, userData, emailType);

      // відправляємо дані про нового користувача на firebase
      // await SendUserDataToBackend(userData, firestore, t);

      // надсилаємо повідомлення в Discord
      // sendDataToDiscord(userData);

      toast.success(t("registration_success"));

      handleCloseModal(); // закриваємо модальне вікно
    } catch (error) {
      toast.error(t("error_occurred"));
      console.error(error);
      return; // додали return
    } // Обробка помилок реєстрації
  };

  const handleInput = (value, setter, pattern = /^[a-zA-Z0-9\s.@,\/-]*$/) => {
    if (pattern.test(value) || value === "") {
      setter(value);
    }
  };

  const validateForm = async () => {
    if (!firstName.trim() || !lastName.trim()) {
      toast.error(t("enter_full_name"));
      return false;
    }
    const emailPattern = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    if (!emailPattern.test(email.trim())) {
      toast.error(t("enter_valid_email"));
      return false;
    }
    if (password.length < 6) {
      toast.error(t("password_minimum_length"));
      return false;
    }
    if (password !== confirmPassword) {
      toast.error(t("passwords_do_not_match"));
      return false;
    }
    if (!permission) {
      toast.error(t("confirm_personal_data_processing_consent"));
      return false;
    }
    if (!selectedCountry) {
      toast.error(t("select_country"));
      return false;
    }
    const phonePattern = /^\+?\d{10,15}$/;
    if (!phonePattern.test(phoneNumber.trim())) {
      toast.error(t("enter_valid_phone_number"));
      return false;
    }

    // Перевіряємо промокод
    if (Object.keys(promo).length) {
      const promoIsValid = await validatePromoCode();
      if (!promoIsValid) {
        return false;
      }
    }
    return true;
  };

  const validatePromoCode = async () => {
    const promoCodePattern = /^[0-9]{5}-[0-9]$/;
    if (!promo.promoCode || !promoCodePattern.test(promo.promoCode)) {
      toast.error(`${t("promo_code")} ${t("rejected")}`);
      setInputState("red");
      setTimeout(() => setInputState("idle"), 1000);
      setPromo({});
      return false;
    }

    const [promoId, promoValue] = promo.promoCode.split("-");

    try {
      const promoRef = doc(firestore, "promo", promoId);
      const promoSnap = await getDoc(promoRef);
      if (!promoSnap.exists()) {
        toast.error(`${t("promo_code_not_found")}`);
        setInputState("red");
        return false;
      }

      const promoData = promoSnap.data();
      let availableCodes = promoData.available
        ? promoData.available.split(", ")
        : [];
      let activeCodes = promoData.active ? promoData.active.split(", ") : [];
      let inactiveCodes = promoData.inactive
        ? promoData.inactive.split(", ")
        : [];

      if (
        !availableCodes.includes(promoValue) ||
        activeCodes.includes(promoValue) ||
        inactiveCodes.includes(promoValue)
      ) {
        toast.error(`${t("promo_code")} ${t("rejected")}`);
        setInputState("red");
        return false;
      }

      const userPromoData = {
        promoCode: promo.promoCode,
        date: formatDateTime(new Date()),
        discount: 100,
        name: promoData.name,
        type: promoData.type,
      };

      setPromo(userPromoData);
      setUserData({ ...userData, promo: userPromoData });
      // updateUserData(userPromoData);

      setTimeout(() => {
        setInputState("idle");
      }, 1000);

      // toast.success(`${t("promo_code")} ${t("submited")}`);
      return true;
    } catch (error) {
      console.error("Error validating promo code:", error);
      toast.error(`${t("error_occurred")}`);
      setInputState("red");
      return false;
    }
  };

  const handleBirthDateInput = (e) => {
    let value = e.target.value.replace(/\D/g, "");
    if (value.length >= 2) {
      value = value.slice(0, 2) + "." + value.slice(2);
    }
    if (value.length >= 5) {
      value = value.slice(0, 5) + "." + value.slice(5, 9);
    }
    setBirthDate(value);
  };

  // Обробник сабміту з активацією промокоду
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Запускаємо реєстрацію
    await handleSignUp(e);

    // Якщо промокод вказаний, запускаємо активацію
    if (Object.keys(promo).length && promo.promoCode) {
      try {
        await markPromoAsUsed(firestore, promo.promoCode);
      } catch (error) {
        console.error(`🚨 Помилка активації промокоду: ${error}`);
        toast.error(t("promo_activation_failed"));
      }
    }
  };

  return (
    <form className="loginForm" onSubmit={handleFormSubmit}>
      <div className="col center">
        <p className="loginForm__text--bold">
          {t("student_registration_form")}
        </p>
        <p>({t("form_1")})</p>

        <p className="loginForm__text--italic">{t("information_in_latin")}</p>
      </div>

      <div className="loginForm__row">
        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="firstName">
            {t("name")}
          </label>
          <input
            className="loginForm__input"
            type="text"
            id="firstName"
            value={firstName}
            onChange={(e) => handleInput(e.target.value, setFirstName)}
            placeholder={t("name")}
            required
          />
        </div>

        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="lastName">
            {t("surname")}
          </label>
          <input
            className="loginForm__input"
            type="text"
            id="lastName"
            value={lastName}
            onChange={(e) => handleInput(e.target.value, setLastName)}
            placeholder={t("surname")}
            required
          />
        </div>
      </div>

      <div className="loginForm__col">
        <label className="loginForm__label" htmlFor="email">
          {t("email_address")}
        </label>
        <input
          className="loginForm__input"
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder={t("email_address")}
          required
        />
      </div>

      <div className="loginForm__row">
        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="password">
            {t("password")}
          </label>
          <input
            className="loginForm__input"
            id="password"
            type="password"
            value={password}
            onChange={(e) => handleInput(e.target.value, setPassword)}
            placeholder={t("password")}
            required
          />
        </div>

        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="confirm-password">
            {t("confirm_password")}
          </label>
          <input
            className="loginForm__input"
            id="confirm-password"
            type="password"
            value={confirmPassword}
            onChange={(e) => handleInput(e.target.value, setConfirmPassword)}
            placeholder={t("confirm_password")}
            required
          />
        </div>
      </div>

      <div className="loginForm__col">
        <label htmlFor="country">{t("country_of_residence")}:</label>
        <Select
          options={countries}
          value={selectedCountry}
          onChange={setSelectedCountry}
          id="country"
          required
        />
      </div>

      <div className="loginForm__col">
        <label className="loginForm__label" htmlFor="address">
          {t("address")}
        </label>
        <input
          id="address"
          className="loginForm__input"
          type="text"
          value={address}
          onChange={(e) => handleInput(e.target.value, setAddress)}
          placeholder={t("address_fields")}
          required
        />
      </div>

      <div className="loginForm__row">
        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="birthdate">
            {t("date_of_birth")}
          </label>
          <input
            id="birthdate"
            className="loginForm__input"
            type="text"
            value={birthDate}
            onChange={handleBirthDateInput}
            placeholder="dd.mm.yyyy"
            required
          />
        </div>

        <div className="loginForm__col">
          <label className="loginForm__label" htmlFor="phoneNumber">
            {t("phone_number")}
          </label>
          <input
            className="loginForm__input"
            type="phoneNumber"
            id="phoneNumber"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="+380995554433"
            required
          />
        </div>
      </div>

      <div className="loginForm__col">
        <label className="loginForm__label" htmlFor="promo_code_optional">
          {t("promo_code_optional")}
        </label>
        <input
          className={`loginForm__input ${
            inputState === "red" ? "loginForm__input--error" : ""
          } ${inputState === "green" ? "loginForm__input--success" : ""}`}
          id="promo_code_optional"
          type="text"
          value={promo.promoCode || ""}
          onChange={(event) =>
            setPromo({ ...promo, promoCode: event.target.value })
          }
          placeholder={t("enter_promo_code")}
        />
      </div>

      <div className="permission__wrap">
        {below18 ? (
          <section className="permission__container">
            <div className="permission">
              <input
                className="permission__input"
                type="checkbox"
                id="below18"
                checked={below18}
                onChange={(e) => setBelow18(e.target.checked)}
              />
              <label
                className="permission__label permission__underlined"
                htmlFor="below18"
              >
                {t("under_18")}
              </label>
            </div>

            <div className="col center">
              <p className="loginForm__text--bold">
                {t("representative_registration_form")}
              </p>
              <p>({t("form_2")})</p>
            </div>

            <p className="permission__warning">
              {t("filled_by_parents_or_legal_representatives")}. {"  "}
              {t("information_in_latin")}
            </p>

            <div className="loginForm__row">
              <div className="loginForm__col">
                <label className="loginForm__label" htmlFor="relativefirstName">
                  {t("name")}
                </label>
                <input
                  className="loginForm__input"
                  type="text"
                  id="relativefirstName"
                  value={relativeFirstName}
                  onChange={(e) => setRelativeFirstName(e.target.value)}
                  placeholder={t("name")}
                  required={below18}
                />
              </div>

              <div className="loginForm__col">
                <label className="loginForm__label" htmlFor="relativelastName">
                  {t("surname")}
                </label>
                <input
                  className="loginForm__input"
                  type="text"
                  id="relativelastName"
                  value={relativeLastName}
                  onChange={(e) => setRelativeLastName(e.target.value)}
                  placeholder={t("surname")}
                  required={below18}
                />
              </div>
            </div>

            <div className="loginForm__col">
              <label className="loginForm__label" htmlFor="relativeEmail">
                {t("email_address")}
              </label>
              <input
                className="loginForm__input"
                type="email"
                id="relativeEmail"
                value={relativeEmail}
                onChange={(e) => setRelativeEmail(e.target.value)}
                placeholder={t("email_address")}
                required={below18}
              />
            </div>

            <div className="loginForm__col">
              <label htmlFor="country">{t("country_of_residence")}:</label>
              <Select
                options={countries}
                value={relativeSelectedCountry}
                onChange={setRelativeSelectedCountry}
                id="country"
                required={below18}
              />
            </div>

            <div className="loginForm__col">
              <label className="loginForm__label" htmlFor="relativeAddress">
                {t("address")}
              </label>
              <input
                id="relativeAddress"
                className="loginForm__input"
                type="text"
                value={relativeAddress}
                onChange={(e) =>
                  handleInput(e.target.value, relativeSetAddress)
                }
                placeholder={t("address_fields")}
                required={below18}
              />
            </div>

            <div className="loginForm__row">
              <div className="loginForm__col">
                <label className="loginForm__label" htmlFor="relativeBirthdate">
                  {t("date_of_birth")}
                </label>
                <input
                  id="relativeBirthdate"
                  className="loginForm__input"
                  type="text"
                  value={relativeBirthDate}
                  onChange={(e) => setRelativeBirthDate(e.target.value)}
                  placeholder={t("date_of_birth")}
                />
              </div>

              <div className="loginForm__col">
                <label
                  className="loginForm__label"
                  htmlFor="relativePhoneNumber"
                >
                  {t("phone_number")}
                </label>
                <input
                  className="loginForm__input"
                  type="phoneNumber"
                  id="relativePhoneNumber"
                  value={relativePhoneNumber}
                  onChange={(e) => setRelativePhoneNumber(e.target.value)}
                  placeholder="+380995554433"
                  required={below18}
                />
              </div>
            </div>
          </section>
        ) : (
          <div className="permission">
            <input
              className="permission__input"
              type="checkbox"
              id="below18"
              checked={below18}
              onChange={(e) => setBelow18(e.target.checked)}
            />
            <label className="permission__label" htmlFor="below18">
              {t("under_18")}
            </label>
          </div>
        )}

        <div className="permission">
          <input
            className="permission__input"
            type="checkbox"
            id="permission"
            checked={permission}
            onChange={(e) => setPermission(e.target.checked)}
            required
          />
          <label className="permission__label" htmlFor="permission">
            {t("consent_to_process_personal_data")}
          </label>
        </div>

        <div className="permission">
          <input
            className="permission__input"
            type="checkbox"
            id="isOffer"
            checked={isOffer}
            onChange={(e) => setIsOffer(e.target.checked)}
            required
          />
          <label className="permission__label" htmlFor="isOffer">
            {t("agreement_acknowledgment")} ({t("public_offer")})
          </label>
        </div>
      </div>

      <button type="submit" className="loginForm__btn">
        {t("register")}
      </button>

      <div className="loginForm__offer">
        <DownloadFile />
      </div>
    </form>
  );
};
